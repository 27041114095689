<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 34 27"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M32.3 4.4c-.8 0-1.2-.3-2-1-.8-1-2-2-4.2-2s-3.3 1-4.2 2c-.7.7-1.1 1-2 1-.9 0-1.2-.3-2-1-.8-1-2-2-4.2-2s-3.3 1-4.2 2c-.7.7-1.1 1-2 1-.8 0-1.2-.3-2-1-.8-1-2-2-4.2-2m31 13.1c-.8 0-1.2-.3-2-1-.8-1-2-2-4.2-2s-3.3 1-4.2 2c-.7.7-1.1 1-2 1-.9 0-1.2-.3-2-1-.8-1-2-2-4.2-2s-3.3 1-4.2 2c-.7.7-1.1 1-2 1-.8 0-1.2-.3-2-1-.8-1-2-2-4.2-2m31 14.2c-.8 0-1.2-.3-2-1-.8-.9-2-2-4.2-2s-3.3 1.1-4.2 2c-.7.7-1.1 1-2 1-.9 0-1.2-.3-2-1-.8-.9-2-2-4.2-2s-3.3 1.1-4.2 2c-.7.7-1.1 1-2 1-.8 0-1.2-.3-2-1-.8-.9-2-2-4.2-2"
    />
  </svg>
</template>
